<template>
  <div class="media-manager mm relative">
    <spinner overlay="absolute" :show="isLoading" />
    <div class="mm__toolbar-wrapper relative z-level-1">
      <div class="mm__toolbar flex-between flex-wrap gap-m">
        <div class="buttons flex flex-wrap gap-m">
          <form-button
            size="small"
            theme="success"
            s-role="media-manager-button"
            icon="cloud-upload"
            text="media.manager.uploadButtonText"
            @click.prevent.stop="startUploadProcess"></form-button>
          <form-button
            v-show="currentItem != false"
            class="animate__fadeIn animate--fastest"
            size="small"
            theme="danger"
            s-role="media-manager-button"
            icon="cloud-upload"
            text="media.manager.deleteButtonText"
            @click.prevent.stop="deleteCurrentFile"></form-button>
          <form-button
            v-show="currentItem != false"
            class="animate__fadeIn animate--fastest"
            size="small"
            theme="gray-5"
            icon="pencil"
            s-role="media-manager-button"
            text="media.manager.renameButtonText"
            @click.prevent.stop="startRenameProcess"></form-button>
          <form-button
            v-show="currentItem != false"
            class="animate__fadeIn animate--fastest"
            size="small"
            theme="gray-5"
            icon="clipboard"
            s-role="media-manager-button"
            text="media.manager.copyUrlButtonText"
            @click.prevent.stop="copyCurrentFileUrl"></form-button>
          <form-button
            v-if="mediaSelect"
            v-show="currentItem != false"
            class="animate__fadeIn animate--fastest"
            size="small"
            theme="success"
            icon="check"
            s-role="media-manager-button"
            text="media.manager.selectButtonText"
            @click.prevent.stop="selectItem(currentItem)"></form-button>
        </div>
        <div class="localFilters">
          <form-input
            v-model="textFilter"
            :label="false"
            size="small"
            placeholder="media.manager.searchFieldPlaceholder" />
        </div>
      </div>
    </div>

    <div class="bar-loader-wrapper overflow-hidden">
      <bar-loader
        :fixed="false"
        style="width: 100%"
        :active="isAboutToLoad || mediaManager.isLoadingItems.value" />
    </div>

    <div class="mm__items relative "
         ref="itemsContainer"
         @dragenter.prevent.stop="handleFileDragEnter"
         @dragover.prevent.stop=""
         @dragleave.prevent.stop="handleFileDragLeave"
         @drop.prevent.stop="handleFileDrop">
      <div

        v-show="isDraggingFiles"
        style="box-shadow: 0 0 15px 2px rgba(0,0,0,0.8) inset; pointer-events: none;background: rgba(0,0,0,0.8)"
        class="z-level-5 drag-overlay size-parent c-bg
        flex-middle flex-center flex-column absolute-cover animate__fadeIn animate__faster" >
        <div class="content width-expand size-parent flex-middle flex-center flex-column gap-l">
          <span class="text-2xl text-bold" >{{ tu('core.mediaManager.dropFilesToUpload') }}.</span>
          <icon icon="image" size="5" theme="bg"/>
        </div>

      </div>
      <div class="mm__items-list flex gap-l flex-wrap relative">
        <div
          v-for="item of items"
          :key="item.id"
          v-click-outside="{
            handler: () => (currentItem = false),
            middleware: itemClickOutsideMiddleware,
          }"
          class="mm-item"
          :class="{ current: currentItem === item }"
          @dblclick="selectItem(item)"
          @click.prevent.stop="currentItem = item">
          <div class="mm-item__preview">
            <div
              class="mm-item__preview-inner"
              :style="`background-image: url('${item.thumbUrl}')`"></div>
          </div>
          <div class="mm-item__meta">
            <div class="mm-item__name">
              {{ item.fileName }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="hasMoreItems"
        class="load-more margin-xl-top flex flex-middle flex-center margin-auto with-expand">
        <form-button
          theme="success"
          text="load more"
          @click.prevent="mediaManager.loadMore()"></form-button>
      </div>
      <div
        v-if="!items || items.length < 1"
        class="no-items-found flex flex-middle flex-center margin-auto with-expand">
        <h3>{{ translate("media.manager.noItems") }}</h3>
      </div>
    </div>
    <div class="mm__footer-wrapper">
      <div class="mm__footer flex flex-wrap gap-m">
        <div v-if="mediaManagerHasError">
          <div class="flex gap-s">
            <icon icon="warning-triangle" theme="danger" />
            <span>{{ translate("core.genericError") }}</span>
          </div>
        </div>
        <!-- this forces minimum of 1 line height -->
        <span>&nbsp;</span>
        <file-input
          ref="fileUploadField"
          v-model="uploadFiles"
          class="hidden"
          multiple="true"
          label="file upload" />
      </div>
    </div>
  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import useMediaManager from "@/client/extensions/composition/useMediaManager";

export default {
  props: {
    mediaSelect: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Object,
      default: {},
    },
    uploadData: {
      type: Object,
      default: {},
    },
  },
  emits: ["media:item-selected"],
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    const mediaManager = useMediaManager(props, {});
    return { asyncOps, asyncOpsReady, asyncStatus, mediaManager };
  },
  data: function () {
    return {
      isDraggingFiles: false,
      isAboutToLoad: false,
      textFilter: "",
      textFilterDebounce: false,
      filterDebounce: false,
      isLoading: false,
      uploadFiles: false,
      mediaManagerHasError: this.mediaManager.hasError,
      items: this.mediaManager.items,
      currentItem: false,
      localFilters: {},
    };
  },
  computed: {
    finalFilters() {
      return { ...this.filters, ...this.localFilters };
    },
    hasMoreItems() {
      return Number(this.mediaManager.totalItems.value) > Number(this.items.length);
    },
    hasSelectedItem() {
      return !!this.currentItem;
    },
  },
  watch: {
    uploadFiles(newVal) {
      if (!newVal || newVal.length < 1) {
        return true;
      }
      this.uploadFilesByState();
    },
    textFilter(newVal) {
      this.isAboutToLoad = true;
      clearTimeout(this.textFilterDebounce);
      this.textFilterDebounce = utilities.setClientTimeout(() => {
        this.setCurrentTextFilterValueAsMediaFilter();
        this.isAboutToLoad = false;
      }, 350);
    },
    finalFilters: {
      handler(newVal, oldVal) {
        this.applyFilters(oldVal);
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.sg("user.initialFetchCompletePromise");
    this.$nextTick(() => {
      this.mediaManager.loadItems();
    });
  },
  methods: {
    handleFileDragEnter(event){
      let dropDiv = this.$refs.itemsContainer;
      if (!dropDiv.contains(event.relatedTarget)) {
        this.isDraggingFiles = true;
      }
    },
    handleFileDragLeave() {
      let dropDiv = this.$refs.itemsContainer;
      if (!dropDiv.contains(event.relatedTarget)) {
        this.isDraggingFiles = false;
      }
    },
    handleFileDrop(event) {
      if (! event?.dataTransfer?.files) {
       return;
      }
      try {
        this.$refs.fileUploadField.$el.files = event.dataTransfer.files;
        this.uploadFiles = event.dataTransfer.files;
        this.isDraggingFiles = false;
      } catch(e) {

      }
    },
    selectItem(item) {
      if (this.mediaSelect) {
        this.$emit("media:item-selected", { item });
        this.currentItem = null;
      }
    },
    applyFilters(oldFilters) {
      this.isAboutToLoad = true;
      clearTimeout(this.filterDebounce);
      this.filterDebounce = utilities.setClientTimeout(() => {
        // clear all existing finalFilters
        if (oldFilters) {
          for (const [key, value] of Object.entries(oldFilters)) {
            this.mediaManager.unsetFilter(key);
          }
        }

        // set new finalFilters by state
        for (const [key, value] of Object.entries(this.finalFilters)) {
          let hasVal = value && value !== "";
          hasVal
            ? this.mediaManager.setFilter(key, value)
            : this.mediaManager.unsetFilter(key);
        }

        // do not forget the text filter - it is "special" at the moment
        let hasVal = this.textFilter && this.textFilter !== "";
        hasVal
          ? this.mediaManager.setFilter("fileName", this.textFilter)
          : this.mediaManager.unsetFilter("fileName");
        this.isAboutToLoad = false;
      }, 350);
    },
    setCurrentTextFilterValueAsMediaFilter() {
      let hasVal = this.textFilter && this.textFilter !== "";
      return hasVal
        ? this.mediaManager.setFilter("fileName", this.textFilter)
        : this.mediaManager.unsetFilter("fileName");
    },
    itemClickOutsideMiddleware(event, el) {
      if (utilities.isSSR()) {
        return false;
      }

      let parent = event.target.closest("button");
      let attr = event.target.getAttribute("s-role");
      let parentAttr = parent ? parent.getAttribute("s-role") : false;
      let isIgnore =
        attr === "media-manager-button" || parentAttr === "media-manager-button";

      return !isIgnore;
    },
    startUploadProcess() {
      try {
        this.$refs.fileUploadField.promptFileSelection();
      } catch (e) {
        warn("failed to focus file input", e);
      }
    },
    async uploadFilesByState() {
      let hasError = false;

      // basic validate the files
      for (const file of  this.uploadFiles) {
        if (file.size / 1000000 > config.mediaManager.maxFileSizeMB) {
          this.$s.ui.notification('media.manager.fileTooLargeNotification', 'error', {contentParams: {maxSizeMB:config.mediaManager.maxFileSizeMB }});
          return false;
        }
      }

      this.isLoading = true;
      for (const file of this.uploadFiles) {
        const uploadResult = await this.mediaManager.upload(file, {
          data: this.uploadData,
        });

        if (!uploadResult) {
          hasError = true;
        }
      }

      if (hasError) {
        this.$s.ui.notification("media.manager.uploadErrorOccurred", "error");
        this.isLoading = false;
        return;
      }

      await this.mediaManager.reloadItems();
      this.isLoading = false;
    },
    deleteCurrentFile() {
      const id = this.currentItem.id;
      this.$s.ui.modal
        .confirm()
        .then(async () => {
          this.isLoading = true;
          if (!(await this.mediaManager.delete(id))) {
            this.isLoading = false;
            return this.$s.ui.notification("core.errorGeneric");
          }

          this.mediaManager.reloadItems();
          this.$s.ui.notification("core.successGeneric", "success");
          this.isLoading = false;
        })
        .catch((e) => {
          this.$s.ui.notification("core.actionCanceled");
          this.isLoading = false;
        });
    },
    async startRenameProcess() {
      let res = false;
      let handle = this.currentItem.handle;
      let id = this.currentItem.id;
      let splitName = this.currentItem.fileName.split(".");
      let oldName = splitName[0];
      let extension = splitName[1];
      try {
        res = await this.$s.ui.modal.prompt(null, "media.manager.renamePromptTitle", {
          initialValue: oldName,
          field: {
            name: "newName",
            label: "media.manager.promptRenameFieldLabel",
          },
        });
      } catch (e) {
        return true;
      }

      //notice  the modal has deselected our currentItem, as it is a click outside
      if (!res || res === "" || res.length < 3) {
        this.$s.ui.notification("media.manager.renameErrorInvalidInput", "error");
        return false;
      }
      const newName = res + "." + extension;
      this.isLoading = true;
      let result = await this.mediaManager.rename(id, newName);

      await this.mediaManager.reload();
      this.isLoading = false;

      if (!result) {
        this.$s.ui.notification("core.errorGeneric", "error");
        return;
      }

      this.$s.ui.notification("core.successGeneric", "success");
      return;
      const split = res.split(".");
      console.log(res);
    },
    async copyCurrentFileUrl() {
      await navigator.clipboard.writeText(this.currentItem.url);
      this.$s.ui.notification("media.manager.urlCopied");
    },
  },
};
</script>

<style scoped lang="scss"></style>
